import LsApi from "~/Src/Components/Api/Api";
import LsCarousel from "../Carousel/Carousel";
import LsLogger from "../Logging/Logger";
import "./ProductRecommendations.scss";

export class LsRecommendationModel {
    contextData: string;
    location: string;
    referrer: string;
    items: Array<{ [key: string]: string }>;
}

class LsRecommendationDefaults {
    public static container = "[data-recommendation-container]";
    public static urlAttribute = "data-url";
    public static contextId = "lsRecommendationContext";
}

export class LsRecommendations {
    protected static _document: Document;
    protected static get document() {
        return LsRecommendations._document || (LsRecommendations._document = window.document);
    }

    protected static _context: string;
    protected static get context() {
        if (LsRecommendations._context === undefined) {
            const contextElement = LsRecommendations.document.getElementById(LsRecommendationDefaults.contextId);
            if (contextElement !== undefined) {
                return LsRecommendations._context = contextElement.textContent;
            }
            LsLogger.log(`LsRecommendations.context - lsRecommendationContext not found - could not load recommendations`, "fatal");
        }
        return LsRecommendations._context;
    }

    protected static _location: string;
    protected static get location() {

        return LsRecommendations._location || (LsRecommendations._location = LsRecommendations.document.location.href);
    }

    protected static _referrer: string;
    protected static get referrer() {
        return LsRecommendations._referrer || (LsRecommendations._referrer = LsRecommendations.document.referrer);
    }

    protected static _recommendationUrl: string;
    public static get recommendationUrl() {

        if (LsRecommendations._recommendationUrl === undefined) {
            const recommendationContainers = Array.from(LsRecommendations.document.querySelectorAll(LsRecommendationDefaults.container));
            for (const recommendationContainer of recommendationContainers) {
                if (recommendationContainer.hasAttribute(LsRecommendationDefaults.urlAttribute)) {
                    const url = recommendationContainer.getAttribute(LsRecommendationDefaults.urlAttribute);
                    if (url) {
                        return LsRecommendations._recommendationUrl = url;
                    }
                }
            }

            LsLogger.log(`LsRecommendations.recommendationsUrl - Url not found - element ${LsRecommendationDefaults.container} does not contain [${LsRecommendationDefaults.urlAttribute}] to load recommendations`, "fatal");
        }

        return LsRecommendations._recommendationUrl;
    }

    protected static _containers: Array<HTMLElement>;
    protected static get containers() {
        return LsRecommendations._containers || (LsRecommendations._containers = Array.from<HTMLElement>(LsRecommendations.document.querySelectorAll(LsRecommendationDefaults.container)));
    }

    public constructor() {
        if (document.readyState === "loading") {
            document.addEventListener("DOMContentLoaded", LsRecommendations.getRecommendations);
        } else {
            LsRecommendations.getRecommendations();
        }
    }

    public static getRecommendations() {
        const recommendationInfoArray: Array<{ [key: string]: string }> = [];

        for (const container of LsRecommendations.containers) {
            recommendationInfoArray.push(container.dataset);
        }

        if (recommendationInfoArray.length > 0) {
            const recommendationModel: LsRecommendationModel = {
                contextData: LsRecommendations.context,
                location: LsRecommendations.location,
                referrer: LsRecommendations.referrer,
                items: recommendationInfoArray,
            }

            LsRecommendations.loadRecommendations(LsRecommendations.recommendationUrl, recommendationModel);
        }
    }

    public static loadRecommendations(url: string, data: LsRecommendationModel, containers?: Array<HTMLElement>) {
        LsApi.ajax({
            method: "POST",
            global: false,
            url,
            contentType: "application/json",
            data: JSON.stringify(data),
            success: (result, status, xhr) => {
                if (containers !== null && containers !== undefined && containers.length > 0) {
                    LsRecommendations.removeSlides(containers);
                    LsApi.onSuccess(result, status, xhr);
                    LsRecommendations.refresh(containers);
                } else {
                    LsApi.onSuccess(result, status, xhr);
                    LsRecommendations.removeSlides(LsRecommendations.containers);
                    LsRecommendations.refresh(LsRecommendations.containers);
                }

                LsRecommendations.replaceConstructorResultId();
            },
            error: (xhr, status, err) => {
                if (containers !== null && containers !== undefined && containers.length > 0) {
                    LsRecommendations.removeOnError(containers);
                } else {
                    LsRecommendations.removeOnError(LsRecommendations.containers);
                }
            }
        });
    }

    protected static removeSlides = (containers: Array<HTMLElement>) => {
        for (const container of containers) {
            const carousel = container.querySelector("[data-carousel]");
            LsCarousel.removeAllSlides(carousel);
        }
    }

    protected static refresh = (containers: Array<HTMLElement>) => {
        for (const container of containers) {
            const carousel = container.querySelector("[data-carousel]");
            LsCarousel.refresh(carousel);
        }
    }

    protected static removeOnError = (containers: Array<HTMLElement>) => {
        for (const container of containers) {
            container.parentNode?.removeChild(container);
        }
    }

    protected static replaceConstructorResultId() {
        LsRecommendations.containers.forEach((container) => {
            let $resultIds = $(container).find("[data-constructor-result-id]:not([data-constructor-result-id=''])");

            if ($resultIds.length !== 0) {
                let $resultId = $resultIds.first().data("constructor-result-id");
                $(container).attr("data-cnstrc-result-id", $resultId);
            }
        })
    }
}

export default LsRecommendations;