import "~/Src/EntryPoints/Shared/Default";
import "~/Src/Components/Carousel/ContentCarousel.scss"; // content carousel
import "~/Src/Components/CategoryNavigation/CategoryNavigation.scss"; // category navigation
import "~/Src/Components/FeatureBanner/FeatureBanner.scss";
import "~/Src/Components/UniqueSellingPoints/UniqueSellingPoints.scss";

import LsCarousel from "~/Src/Components/Carousel/Carousel"; // content carousel, category navigation 
import LsProductData from "~/Src/Components/ProductData/ProductData";
import LsProductRecommendations from "~/Src/Components/ProductRecommendations/ProductRecommendations";

var cl = new LsCarousel(); // content carousel, category navigation 
var pd = new LsProductData();
var pr = new LsProductRecommendations();