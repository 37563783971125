import LsApi from "~/Src/Components/Api/Api";
import { LsLogger } from "../Logging/Logger";

interface ILsOfferingData {
    offeringId: number | string;
    selectedItemId?: number | string;
    pricingSelectorId?: string;
    rewardsSelectorId?: string;
    ratingsSelectorId?: string;
    recommendationSelectorId?: string;
    favoriteSelectorId?: string;
    tileType?: string;
    alternateUniqueId?: string;
}

class LsSelectorDefaults {
    public static urlAttribute = "data-url";
    public static offering = "[data-offering-info]";
    public static price = "[data-offering-item-price]";
    public static rewards = "[data-offering-rewards]";
    public static ratings = "[data-offering-ratings]";
    public static alternate = "[data-offering-alternate]";
    public static discontinued = "[data-offering-discontinued]";
    public static favorite = "[data-offering-favorite]";
    public static tileType = "[data-tile-type]";
    public static alternateUniqueId = "data-alternate-unique-id";
}

export class LsProductData {
    protected static _document: Document;
    protected static get document() {
        return LsProductData._document || (LsProductData._document = window.document);
    }

    protected static _additionalInfoUrl: string;
    protected static get additionalInfoUrl() {
        if (LsProductData._additionalInfoUrl === undefined) {
            const offerings = Array.from(LsProductData.document.querySelectorAll(LsSelectorDefaults.offering));
            for (const offering of offerings) {
                if (offering.hasAttribute(LsSelectorDefaults.urlAttribute)) {
                    return LsProductData._additionalInfoUrl = offering.getAttribute(LsSelectorDefaults.urlAttribute);
                }
            }

            LsLogger.log(`LsProductData.additionalInfoUrl - Url not found - element ${LsSelectorDefaults.offering} does not contain [${LsSelectorDefaults.urlAttribute}] to load offering additional information via AJAX`, "fatal");
        }

        return LsProductData._additionalInfoUrl;
    }

    public constructor() {
        if (document.readyState === "loading") {
            document.addEventListener("DOMContentLoaded", LsProductData.getOfferingPricing);
        } else {
            LsProductData.getOfferingPricing();
        }
    }

    public static getOfferingPricing() {
        const offerings = Array.from<HTMLElement>(LsProductData.document.querySelectorAll(LsSelectorDefaults.offering));
        const offeringInfoArray: Array<ILsOfferingData> = [];

        for (const offering of offerings) {
            const id = offering.getAttribute("id");
            const data = offering.dataset;

            if (!data.offeringId || data.ajaxPricingInitialized === "true") {
                continue;
            } else {
                const offeringData: ILsOfferingData = { offeringId: data.offeringId, selectedItemId: data.selectedItemId, tileType: data.tileType };

                if (data.offeringDiscontinued === "true") {
                    const alternate = offering.querySelector(LsSelectorDefaults.alternate);
                    if (alternate) {
                        offeringData.recommendationSelectorId = `#${id} ${LsSelectorDefaults.alternate}`;
                        offeringData.alternateUniqueId = alternate.getAttribute(LsSelectorDefaults.alternateUniqueId);
                    }
                } else {
                    const pricing = offering.querySelector(LsSelectorDefaults.price);
                    if (pricing) {
                        offeringData.pricingSelectorId = `#${id} ${LsSelectorDefaults.price}`;
                    }

                    const rewards = offering.querySelector(LsSelectorDefaults.rewards);
                    if (rewards) {
                        offeringData.rewardsSelectorId = `#${id} ${LsSelectorDefaults.rewards}`;
                    }

                    const ratings = offering.querySelector(LsSelectorDefaults.ratings);
                    if (ratings) {
                        offeringData.ratingsSelectorId = `#${id} ${LsSelectorDefaults.ratings}`;
                    }

                    const favorite = offering.querySelector(LsSelectorDefaults.favorite);
                    if (favorite) {
                        offeringData.favoriteSelectorId = `#${id} ${LsSelectorDefaults.favorite}`;
                    }
                }

                offeringInfoArray.push(offeringData);
                offering.dataset.ajaxPricingInitialized = "true";
            }
        }

        if (offeringInfoArray.length > 0) {
            LsProductData.loadAdditionalInfo(LsProductData.additionalInfoUrl, offeringInfoArray);
        }
    }

    protected static loadAdditionalInfo(url: string, data: Array<ILsOfferingData>) {
        LsApi.ajax({
            method: "POST",
            global: false,
            url,
            contentType: "application/json",
            data: JSON.stringify(data),
            success: (result, status, xhr) => {
                LsApi.onSuccess(result, status, xhr);
            }
        });
    }
}

export default LsProductData;
