import $ = require("jquery");

import "slick-carousel";

import { LsBreakpoints, LsBreakpoint } from "~/Src/Components/Breakpoints/Breakpoints";

import "slick-carousel/slick/slick.scss";
import "./Carousel.scss";

enum LsCarouselArrowType {
    Regular = "regular",
    Circle = "circle",
}

export class ILsCarouselOptions {
    arrowType?: LsCarouselArrowType = LsCarouselArrowType.Circle;
    breakpoints?: {
        [bp: number]: number;
    };
}

export class LsCarouselDefaults {
    public arrowType: LsCarouselArrowType = LsCarouselArrowType.Circle;
}

export class LsCarousel {
    protected static _document: Document;
    protected static get document() {
        return LsCarousel._document || (LsCarousel._document = document);
    }

    protected static templates: { [id: string]: string; } = {};

    public constructor() {
        if (LsCarousel.document.readyState === "loading") {
            LsCarousel.document.addEventListener("DOMContentLoaded", this.init);
        } else {
            this.init();
        }

        $(document).on("ajaxPageLoad", this.init);
    }

    protected init = () => {
        const carousels = Array.from(LsCarousel.document.querySelectorAll("[data-carousel]"));
        if (carousels.length > 0) {
            const uninitializedCarousels = carousels.filter(el => !el.classList.contains("slick-initialized"));
            uninitializedCarousels.forEach(this.initializeCarousel);
        }
    }

    public static removeAllSlides = (selector: LsJQuerySelector) => {
        const $selector = $(selector);
        $selector.slick("removeSlide", null, null, true);
    }

    public static refresh = (selector: LsJQuerySelector) => {
        const $selector = $(selector);
        $selector.slick("refresh");
    }

    protected initializeCarousel = (el: Element) => {
        const $carousel = $(el);

        const carouselOptions: ILsCarouselOptions = $.extend({}, new LsCarouselDefaults(), $carousel.data());

        let arrowType: LsCarouselArrowType;
        switch (carouselOptions.arrowType) {
            case LsCarouselArrowType.Circle:
            case LsCarouselArrowType.Regular:
                arrowType = carouselOptions.arrowType;
                break;
            default:
                arrowType = LsCarouselArrowType.Circle;
                break;
        }

        let breakpoints: {
            [bp: number]: number;
        };
        breakpoints = carouselOptions?.breakpoints || {};

        if (!(LsBreakpoint.spf40 in breakpoints)) {
            const keys = Object.keys(breakpoints).sort().filter(key => parseInt(key, 10) < 40);
            breakpoints[LsBreakpoint.spf40] = (keys.length > 0) ? breakpoints[keys[keys.length - 1]] : 1;
        }

        const slickOptions = {
            dots: true,
            customPaging: () => this.getTemplate("#carousel-dot-template"),
            mobileFirst: true,
            infinite: false,
            speed: 300,
            slidesToShow: breakpoints?.["0"] ?? 1,
            slidesToScroll: breakpoints?.["0"] ?? 1,
            arrows: false,
            responsive: [],
            rows: 0,
        };
        for (const key of Object.keys(breakpoints).filter(k => k != "0")) {
            const bp = parseInt(key, 10);
            const responsiveSettings = {
                // Slick breakpoints are not inclusive, so it starts at screen sizes 1px wider than it should
                // Slick uses > instead of >= when comparing screen width to breakpoint settings
                // https://github.com/kenwheeler/slick/issues/2503
                breakpoint: LsBreakpoints.bp(bp, false) - 1,
                settings: {
                    slidesToShow: breakpoints[key],
                    slidesToScroll: breakpoints[key],
                    prevArrow: this.getTemplate(`#carousel-prev-${arrowType}-arrow-template`),
                    nextArrow: this.getTemplate(`#carousel-next-${arrowType}-arrow-template`),
                    draggable: false,
                },
            };
            if (bp >= 40) {
                responsiveSettings.settings["arrows"] = true;
            }
            slickOptions.responsive.push(responsiveSettings);
        }
        $carousel.slick(slickOptions);

        const $closestDrawer = $carousel.closest("[data-drawer]");
        if ($closestDrawer.length > 0) {
            $closestDrawer.one("drawerOpen", (e: LsJQueryEvent) => {
                const carousels = Array.from(e.target.querySelectorAll("[data-carousel]"));
                for (const carousel of carousels) {
                    $(carousel).slick("setPosition");
                }
            });
        }
    }

    protected getTemplate = (id: string) => {
        if (LsCarousel.templates[id] !== undefined) {
            return LsCarousel.templates[id];
        }
        const el = LsCarousel.document.querySelector(id);
        if (el) {
            return LsCarousel.templates[id] = el.textContent;
        }
        return "";
    }
}

export default LsCarousel;